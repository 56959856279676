var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("the-wrapper-dapp", {
    attrs: {
      "is-new-header": true,
      "dapp-img": _vm.headerImg,
      "banner-text": _vm.header,
      "tab-items": _vm.tabs,
      "active-tab": _vm.activeTab,
      "external-contents": "",
      "on-tab": _vm.tabChanged,
      "valid-networks": _vm.validNetworks,
    },
    scopedSlots: _vm._u([
      {
        key: "HeaderBody",
        fn: function () {
          return [
            _c("v-divider", { staticClass: "textMedium my-3" }),
            _c(
              "div",
              { staticClass: "d-flex flex-wrap align-center justify-center" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "text-uppercase textMedium--text font-weight-bold",
                  },
                  [
                    _vm._v(" Total Staked: "),
                    _c("span", { staticClass: "greenPrimary--text" }, [
                      _vm._v(_vm._s(_vm.totalStaked)),
                    ]),
                  ]
                ),
                _c("v-icon", { attrs: { color: "textMedium" } }, [
                  _vm._v("mdi-circle-medium"),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "text-uppercase textMedium--text font-weight-bold",
                  },
                  [
                    _vm._v(" Current APR: "),
                    _c("span", { staticClass: "greenPrimary--text" }, [
                      _vm._v(_vm._s(_vm.currentAprFormatted)),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "v-btn-toggle",
              {
                staticClass:
                  "d-flex align-center justify-center mt-3 white--text",
                attrs: {
                  mandatory: "",
                  borderless: "",
                  "active-class": "expandHeader font-weight-bold",
                  "background-color": "transparent",
                },
                model: {
                  value: _vm.activeTab,
                  callback: function ($$v) {
                    _vm.activeTab = $$v
                  },
                  expression: "activeTab",
                },
              },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "px-md-9 white--text text-transform--initial",
                    class:
                      _vm.activeTab === 0
                        ? "staked-tab-active"
                        : "staked-tab-inactive",
                    attrs: { color: "" },
                  },
                  [_vm._v(" New stake ")]
                ),
                _c(
                  "v-btn",
                  {
                    class: [
                      "px-md-9 white--text text-transform--initial d-flex  flex-column align-center",
                      _vm.activeTab === 1
                        ? "staked-tab-active"
                        : "staked-tab-inactive",
                    ],
                    attrs: { color: "" },
                  },
                  [
                    _c("div", [
                      _c(
                        "div",
                        {
                          class: [
                            "white--text",
                            _vm.activeTab === 1 ? "font-weight-medium" : "",
                          ],
                        },
                        [_vm._v(" My stake ")]
                      ),
                      !_vm.loadingValidators
                        ? _c(
                            "div",
                            { staticClass: "mew-caption textLight--text" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    !_vm.loadingValidators
                                      ? _vm.myETHTotalStaked
                                      : "--"
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "HeaderRight",
        fn: function () {
          return [
            _c("div", { staticClass: "text-right" }, [
              _c(
                "a",
                {
                  staticClass:
                    "greenPrimary--text font-weight-medium text-right",
                  attrs: {
                    href: _vm.getArticle("stake-eth2-mew-web"),
                    target: "_blank",
                  },
                },
                [
                  _vm._v(" New to staking? Learn more "),
                  _c(
                    "v-icon",
                    {
                      staticClass: "ml-1",
                      attrs: { small: "", color: "greenPrimary" },
                    },
                    [_vm._v("mdi-open-in-new")]
                  ),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "tabContent1",
        fn: function () {
          return [
            _c(
              "v-sheet",
              {
                staticClass: "mx-auto",
                attrs: {
                  "min-height": "500px",
                  "max-width": "700px",
                  color: "transparent",
                },
              },
              [
                _c("staked-stepper", {
                  ref: "stakedStepper",
                  attrs: {
                    "current-apr": _vm.handlerStaked.apr,
                    "start-provision": _vm.startProvision,
                    "polling-status": _vm.pollingStatus,
                  },
                  on: { readyToStake: _vm.sendTransaction },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "tabContent2",
        fn: function () {
          return [
            _c(
              "v-sheet",
              {
                staticClass: "py-13 mx-auto",
                attrs: {
                  "min-height": "500px",
                  "max-width": "700px",
                  color: "transparent",
                },
              },
              [
                _c("staked-status", {
                  attrs: {
                    "tx-receipt": _vm.handlerStaked.txReceipt,
                    "pending-hash": _vm.pendingTxHash,
                    validators: _vm.validators,
                    loading: _vm.loadingValidators,
                    amount: _vm.amount,
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }